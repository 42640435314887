import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-image: url(${(p) => p.image});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: grid;
  //grid-row-start: 1fr 1fr ;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 60px;
  justify-content: center;
  position: relative;
  z-index: 1;
  justify-items: center;
  margin-bottom: 100px;
  margin-top: 50px;

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

const Logo = styled.div`
  //background-image: url(${(p) => p.image});
  width: 218px;
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  :hover {
    box-shadow: 0px 0px 30px rgba(var(--naranjargb), 0.3);
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 210px;
    height: 165px;
  }
`;

const Logos = ({ cards }) => {
  
  return (
    <Container>
      <Subcontainer>
        {cards.map((item, index) => {
          return (
            <Logo>
              {" "}
              <img src={item.fields.file.url} alt="empresas logos" />
            </Logo>
          );
        })}
      </Subcontainer>
    </Container>
  );
};

export default Logos;
