import React from "react";
import { BLOCKS, MARKS , INLINES} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Bold = ({ style, children }) => <span style={style}>{children}</span>;

const BoldContentfulTacna = (richText, colorBold, fontSize,fontWeight = "700") => {
  const style = { color: colorBold,fontSize: fontSize,fontWeight: fontWeight };
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) =>   <div className="section" ><Bold style={style}>{text}</Bold></div>,
    },
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });

        return <li>{UnTaggedChildren}</li>;
      },
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return(
        <a
          href={data.uri}
          target='_blank'
          rel= 'noopener noreferrer'
        >{children}</a>
            )
      },
      [BLOCKS.TABLE]: (node, children) => (
        <table>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {

        return (
          <div className="section" >
          <img 
            src={`https:${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />

          </div>
        );
      },
    
    },
  };

  return documentToReactComponents(richText, options);
};

export default BoldContentfulTacna;
